import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTop from "../components/pageTop"

const LegalNotes = () => {

	return (
  	<Layout>
        <Seo title="サイトのご利用に関して" />
        <PageTop classname="" title="サイトのご利用に関して" />

        <div id="main" className="">
            <section className="index-section padding-bottom100">
                <div className="centering-container margin-top50">
                    <div className="centering-box">
                        <h2 className="color-h2-text color-h2-border">利用規約</h2>
                    </div>
                </div>
            </section>

            <section className="index-section padding-bottom100" id="privacy">
                <div className="centering-container margin-top50">
                    <div className="centering-box">
                        <h2 className="color-h2-text color-h2-border">プライバシーポリシー</h2>
                    </div>
                    <h3> 個人情報の利用目的</h3>
                    当サイトではお問い合わせの際名前やメールアドレス等の個人情報を入力いただく場合がございます。
                    取得した個人情報はお問い合わせに対する回答や必要な情報を
                    電子メールなどでご連絡をする場合に利用させていただくものであり、
                    これらの目的以外では利用いたしません。

                    <h3>アクセス解析ツールについて</h3>
                    当ブログでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。
                </div>
            </section>

            <section className="index-section padding-bottom100" id="menseki">
                <div className="centering-container margin-top50">
                    <div className="centering-box">
                        <h2 className="color-h2-text color-h2-border">免責事項</h2>
                    </div>
                    当サイトからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。
                    また当サイトのコンテンツ・情報について、できる限り正確な情報を提供するように努めておりますが、正確性や安全性を保証するものではありません。情報が古くなっていることもございます。
                    当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
                </div>
            </section>

            <section className="index-section padding-bottom100" id="copyright">
                <div className="centering-container margin-top50">
                    <div className="centering-box">
                        <h2 className="color-h2-text color-h2-border">著作権について</h2>
                    </div>
                    当ブログで掲載している文章や画像などにつきましては、無断転載することを禁止します。
                    当ブログは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がございましたら、お問い合わせフォームよりご連絡ください。迅速に対応いたします。
        
                </div>
            </section>

            <section className="index-section padding-bottom100" id="copyright">
                <div className="centering-container margin-top50">
                    <div className="centering-box">
                        <h2 className="color-h2-text color-h2-border">お問い合わせ</h2>
                    </div>
                    当サイトへご質問等がございましたら、お問い合わせフォームよりご連絡ください。迅速に対応いたします。
                    <p className="text-center">
                    <a className="button btn-sticky" href="https://contact.sassa-chikusan.jp">お問い合わせフォームはこちら</a>
                    </p>
                </div>
            </section>
        </div>
  	</Layout>
	)
}

export default LegalNotes
